<template>
  <div class="thankyou-grandparent">
    <section class="thankyou-container">
      <img class="sticker sticker__kayak2" src="/assets/stickers/Kayak.png" />

      <img class="sticker sticker__godtur2" src="/assets/stickers/Godtur.png" />

      <img class="sticker sticker__train2" src="/assets/stickers/Train.png" />

      <div class="thankyou-container__text">
        <h1 class="thankyou-container__text__main-paragraph">
          TAKK FOR DIN DELTAKELSE!
        </h1>
        <h2>
          Du vant dessverre ikke delpremie, men er med i trekningen av
          hovedpremien:
        </h2>

        <img class="thankyou-container__image" src="/assets/premie.png" />

        <h2>
          Hovedpremie trekkes når kampanjeperioden er slutt 14. juli 2024.
          <br />
          <br />Vi kontakter deg hvis du er den heldige vinneren. <br />
          <br />Lykke til!
        </h2>
      </div>

      <div class="thankyou-container__button-parent">
        <router-link to="/">
          <ButtonComponent
            class="thankyou-container__button"
            theme="primary"
            label="HJEM"
          ></ButtonComponent>
        </router-link>

        <a
          href="https://contactus.mdlzapps.com/freiano/contact/no-NO/"
          target="_blank"
        >
          <ButtonComponent
            class="thankyou-container__button"
            theme="primary"
            label="KONTAKT OSS"
          ></ButtonComponent>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    Error,
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.thankyou-grandparent {
  min-height: 100vh;
  padding-bottom: 1rem;
}

.thankyou-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  background-color: white;
  border-radius: 25px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  // min-height: 100vh;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

  text-align: center;

  padding: 50px;

  h2 {
    margin-top: 10px;
    max-width: 600px;
    margin: auto;
  }

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
    margin-bottom: 125px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-bottom: 125px;
  }

  &__image {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 650px;
  }

  &__text {
    max-width: 800px;
    margin: auto;

    @media (max-width: $mobile) {
      margin-top: 0;
    }
  }

  p {
    color: var(--secondary);
  }

  &__button-parent {
    margin-top: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 50px;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }
}

.sticker {
  &__kayak2 {
    top: 25px;
    left: -100px;

    width: 260px;
    height: 260px;

    z-index: 1;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__godtur2 {
    top: 100px;
    right: -75px;

    width: 225px;
    height: 225px;

    z-index: 1;

    @media (max-width: $tablet) {
      top: 725px;
      right: 5px;

      width: 180px;
      height: 180px;
    }
  }

  &__train2 {
    top: 400px;
    right: -75px;

    width: 225px;
    height: 225px;

    z-index: 1;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}
</style>
