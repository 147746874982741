<template>
  <div v-if="!hide">
    <div v-html="value"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,

    delay: {
      type: Number,
      default: 4000,
    },
  },
  data() {
    return {
      hide: false,
    };
  },
  methods: {
    hideError() {
      this.hide = true;
      setTimeout(() => (this.hide = false), this.delay);
    },
  },
};
</script>

<style lang="scss"></style>
