<template><CookieSection /></template>

<script>
import CookieSection from "@/components/CookieSection.vue";

export default {
  components: {
    CookieSection,
  },
};
</script>

<style lang="scss"></style>
