<template>
  <div class="campaign-carusel-parent">
    <img
      class="sticker sticker__godtur animation1"
      src="/assets/stickers/Godtur.png"
    />

    <!-- <img
      class="sticker sticker__cathedral animation2"
      src="/assets/stickers/Cathedral.png"
    /> -->

    <img
      class="sticker sticker__map animation3"
      src="/assets/stickers/Map.png"
    />

    <img
      class="sticker sticker__train animation1"
      src="/assets/stickers/Train.png"
    />

    <img
      class="sticker sticker__harbour2 animation2"
      src="/assets/stickers/Harbour.png"
    />

    <img
      class="sticker sticker__ski animation3"
      src="/assets/stickers/Ski.png"
    />

    <div class="campaign-carusel">
      <div class="campaign-carusel-wrapper container">
        <h1>KAMPANJEPRODUKTER</h1>

        <Carousel
          :per-page="1"
          :perPageCustom="[[992, 3]]"
          :scroll-per-page="false"
          :navigation-enabled="true"
          :pagination-enabled="false"
          :navigation-next-label="nextLabel"
          :navigation-prev-label="prevLabel"
          :mouse-drag="!isDesktop"
          :touch-drag="true"
          :loop="true"
          :navigateTo="[navigateTo, true]"
        >
          <template v-if="isDesktop">
            <Slide
              v-for="(item, index) in houses"
              :key="index"
              :data-index="index + 1"
            >
              <component :is="'div'" class="campaign-carusel__hero">
                <img class="campaign-carusel__hero-image" :src="item.image" />
                <div class="campaign-carusel__hero-content-wrapper">
                  <div class="campaign-carusel__hero-content">
                    <h1 class="campaign-carusel__hero-name">{{ item.name }}</h1>
                  </div>
                </div>
              </component>
            </Slide>
          </template>
          <template v-else>
            <slide data-index="1">
              <component :is="'div'" class="campaign-carusel__hero">
                <img
                  class="campaign-carusel__hero-image"
                  src="/assets/plater.png"
                />
                <div class="campaign-carusel__hero-content-wrapper">
                  <div class="campaign-carusel__hero-content">
                    <h1 class="campaign-carusel__hero-name">
                      FREIA SJOKOLADEPLATER
                    </h1>
                  </div>
                </div>
              </component>
            </slide>
            <slide data-index="2">
              <component :is="'div'" class="campaign-carusel__hero">
                <img
                  class="campaign-carusel__hero-image"
                  src="/assets/stykksak.png"
                />
                <div class="campaign-carusel__hero-content-wrapper">
                  <div class="campaign-carusel__hero-content">
                    <h1 class="campaign-carusel__hero-name">
                      FREIA STYKKSAKER
                    </h1>
                  </div>
                </div>
              </component>
            </slide>
            <slide data-index="3">
              <component :is="'div'" class="campaign-carusel__hero">
                <img
                  class="campaign-carusel__hero-image"
                  src="/assets/pose.png"
                />
                <div class="campaign-carusel__hero-content-wrapper">
                  <div class="campaign-carusel__hero-content">
                    <h1 class="campaign-carusel__hero-name">FREIA POSER</h1>
                  </div>
                </div>
              </component></slide
            >
            <slide data-index="4">
              <component :is="'div'" class="campaign-carusel__hero">
                <img
                  class="campaign-carusel__hero-image"
                  src="/assets/kjeks.png"
                />
                <div class="campaign-carusel__hero-content-wrapper">
                  <div class="campaign-carusel__hero-content">
                    <h1 class="campaign-carusel__hero-name">FREIA KJEKS</h1>
                  </div>
                </div>
              </component></slide
            >
            <slide data-index="5">
              <component :is="'div'" class="campaign-carusel__hero">
                <img
                  class="campaign-carusel__hero-image"
                  src="/assets/bake.png"
                />
                <div class="campaign-carusel__hero-content-wrapper">
                  <div class="campaign-carusel__hero-content">
                    <h1 class="campaign-carusel__hero-name">FREIA BAKE</h1>
                  </div>
                </div>
              </component></slide
            >
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide, Carousel } from "vue-carousel";

export default {
  props: {
    houses: Array,
    isCampaign: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      navigateTo: 0,

      currentSlide: 1,
      nextLabel:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>',
      prevLabel:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/></svg>',
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isDesktop() {
      return this.windowWidth > 992 ? true : false;
    },
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  components: {
    Slide,
    Carousel,
  },
};
</script>

<style lang="scss">
.campaign-carusel-parent {
  display: block;
  position: relative;
}

.campaign-carusel {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8rem 0;
  overflow-x: hidden;

  @media (max-width: $mobile) {
    padding: 6rem 0;
  }

  .container {
    padding-inline: 3rem;

    @media (max-width: $mobile) {
      padding-inline: 1.5rem;
    }
  }

  h2 {
    margin-bottom: 2rem;
    color: var(--primary);

    @media (max-width: $mobile) {
      margin: 0;
    }
  }
  p {
    color: var(--primary);

    @media (max-width: $mobile) {
      margin: 0;
    }
  }

  .VueCarousel {
    &-wrapper {
      padding: 1rem 0rem;

      @media (max-width: $tablet) {
        padding-block: 0rem;
      }
    }

    &-navigation {
      &-prev {
        left: 50px !important;

        @media (min-width: $mobile) {
          transform: translateY(calc(-75%)) translateX(-100%);
        }

        @media (min-width: $mobile) {
          left: 75px !important;
        }

        @media (max-width: $mobile) {
          left: 60px !important;
        }
      }
      &-next {
        right: 50px !important;

        @media (min-width: $mobile) {
          transform: translateY(calc(-75%)) translateX(100%);
        }

        @media (min-width: $mobile) {
          right: 75px !important;
        }

        @media (max-width: $mobile) {
          right: 60px !important;
        }
      }
    }

    &-slide {
      transition: scale 0.3s ease-in;
      -webkit-transition: scale 0.3s ease-in;
    }

    &-navigation-button {
      padding: 0rem 2rem !important;
      outline: 0 !important;

      @media (max-width: $mobile) {
        padding: 0rem 1rem !important;
      }

      svg {
        border-radius: 100%;

        padding: 0.5rem;

        $size: 75px;
        $responsiveSize: 75px;

        width: $size;
        height: $size;
        fill: var(--primary);

        @media (max-width: $mobile) {
          width: $responsiveSize;
          height: $responsiveSize;
          padding: 0.5rem;
        }
      }
    }
  }

  &__hero {
    color: var(--primary);

    position: relative;

    &-content-wrapper {
      position: absolute;
      bottom: -2rem;
      width: 100%;

      @media (max-width: $tablet) {
        bottom: -1rem;
      }

      @media (max-width: $mobile) {
        bottom: -0.5rem;
      }
    }

    &-content {
      display: flex;
      justify-content: center;

      h1 {
        color: var(--primary);
      }
    }

    &-image {
      object-fit: contain;
      object-position: center;

      border-radius: 100%;

      aspect-ratio: 1;
      width: 100%;
      height: 75%;
    }
    &-name {
      transition: font-size 0.5s ease;
      -webkit-transition: font-size 0.5s ease;

      margin: 0;
      width: initial;

      white-space: nowrap;
      font-size: 22px;
    }
  }
}

.sticker {
  &__cathedral {
    top: -150px;
    left: -75px;

    width: 320px;
    height: 320px;

    z-index: 1;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__godtur {
    top: -200px;
    right: 25px;

    width: 320px;
    height: 320px;

    z-index: 1;

    @media (max-width: $tablet) {
      top: -100px;
      right: 0px;

      width: 170px;
      height: 170px;
    }
  }

  &__map {
    bottom: -150px;
    left: 0px;

    width: 320px;
    height: 320px;

    z-index: -1;

    @media (max-width: $tablet) {
      bottom: -50px;
      left: 0px;

      width: 160px;
      height: 160px;
    }
  }

  &__train {
    bottom: -150px;
    left: 300px;

    width: 320px;
    height: 320px;

    z-index: -1;

    @media (max-width: $tablet) {
      bottom: -50px;
      left: 200px;

      width: 160px;
      height: 160px;
    }
  }

  &__harbour2 {
    bottom: -100px;
    left: 750px;

    width: 240px;
    height: 240px;

    z-index: -1;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__ski {
    bottom: -40px;
    right: -80px;

    width: 240px;
    height: 240px;

    z-index: -1;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}
</style>
