<template>
  <div class="container">
    <Error />
  </div>
</template>
<script>
import Error from "@/components/Error.vue";

export default {
  components: {
    Error,
  },
};
</script>

<style lang="scss"></style>
