<template>
  <section class="howitworks-container">
    <img
      class="sticker sticker__bag animation1"
      src="/assets/stickers/Bag.png"
    />

    <img
      class="sticker sticker__mountain animation2"
      src="/assets/stickers/Mountain.png"
    />

    <img
      class="sticker sticker__love animation3"
      src="/assets/stickers/Love.png"
    />

    <div class="howitworks__text">
      <h1>SLIK FUNGERER DET</h1>

      <h2>
        Kjøp et Freia-produkt, fyll inn EAN-koden fra pakningen og vinn flotte
        premier!
      </h2>

      <h1 class="howitworks__text__hovedpremie">1X HOVEDPREMIE</h1>
    </div>

    <img class="howitworks__premie" src="/assets/premie.png" />

    <h1 class="howitworks__text__hovedpremie">
      DELPREMIER: En vinner hver dag!
    </h1>

    <div class="howitworks__bullets">
      <div class="howitworks__bullet">
        <img src="/assets/paraply.png" />
        <h1 class="howitworks__bullets__text">
          PARAPLY
        </h1>
      </div>

      <div class="howitworks__bullet">
        <img src="/assets/flaske.png" />
        <h1 class="howitworks__bullets__text">
          VANNFLASKE
        </h1>
      </div>

      <div class="howitworks__bullet">
        <img src="/assets/handkle.png" />
        <h1 class="howitworks__bullets__text">
          BADEHÅNDKLE
        </h1>
      </div>
    </div>

    <router-link to="/terms">
      <ButtonComponent
        class="howitworks__button"
        theme="primary"
        label="VILKÅR"
      ></ButtonComponent>
    </router-link>
  </section>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.howitworks-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  background-color: white;
  border-radius: 25px;
  margin: auto;
  margin-top: 75px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

  text-align: center;

  padding: 50px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
    margin-top: 150px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
  }
}

.howitworks {
  &__text {
    max-width: 650px;
    margin: auto;

    h2 {
      margin-top: 25px;
    }

    &__hovedpremie {
      margin-top: 50px;
      font-size: 2rem;
      line-height: 26px;

      @media (max-width: $mobile) {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.357rem;
      }
    }
  }

  &__premie {
    width: 90%;
    margin-top: 10px;

    @media (max-width: $mobile) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.357rem;
    }
  }

  &__bullets {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-top: 20px;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 100%;
      max-width: 300px;
    }

    &__text {
      font-size: 1.375rem;
    }
  }

  &__bullet {
    width: 30%;

    @media (max-width: $mobile) {
      width: 50%;
    }
  }

  &__button {
    margin-top: 50px;
  }
}

.sticker {
  &__bag {
    top: 400px;
    right: -225px;

    width: 350px;
    height: 350px;

    z-index: 1;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__mountain {
    bottom: -60px;
    left: -250px;

    width: 275px;
    height: 275px;

    z-index: 1;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &__love {
    bottom: -175px;
    left: -150px;

    width: 250px;
    height: 250px;

    z-index: 1;

    @media (max-width: $tablet) {
      bottom: -125px;
      left: 5px;

      width: 155px;
      height: 155px;
    }
  }
}
</style>
