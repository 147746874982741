<template
  ><div class="terms-container-parent">
    <div class="terms-container">
      <img
        class="sticker sticker__harbour animation1"
        src="/assets/stickers/Harbour.png"
      />

      <img
        class="sticker sticker__mountain2 animation2"
        src="/assets/stickers/Mountain.png"
      />

      <img
        class="sticker sticker__church animation3"
        src="/assets/stickers/Church.png"
      />

      <div class="terms-container__text">
        <h1>VILKÅR</h1>

        <br />
        <br />

        <div class="terms-container__text__sub-paragraph">
          <div>
            <p class="terms-container__text__sub-paragraph__subtitle__main ">
              <span>
                FREIA VINN NORGESFERIE KONKURRANSEVILKÅR: VINN NORGESFERIE</span
              >
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>ARRANGØR AV KONKURRANSEN</span>

              <br />
              Mondelez Norge AS («Mondelez») <br />Johan Throne Holsts Plass 1,
              <br />P.O. Box 6658 Rodeløkka,<br />NO-0502, Oslo
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Pulse Communications AS, Askekroken 11, 0277 Oslo, Norge,
              håndterer det tekniske rundt konkurransen.Consultix GmbH,
              Wachtstr. 17-24, 28195 Bremen, Tyskland, håndterer trekningen av
              konkurransen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>AKSEPT AV VILKÅR OG BETINGELSER</span><br />
              Ved å delta i konkurransen aksepterer deltakeren
              konkurransereglene. Denne kampanjen er åpen for innbyggere i Norge
              som er fylt 18 år, med unntak av arrangørens ansatte,
              representanter og alle andre med en profesjonell tilknytning til
              denne kampanjen.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>PREMIE</span>

              <br />
              Hovedpremien er en Norgesferie til en verdi av kr 100 000,-. Ved å
              registrere seg i konkurransen er du med i trekningen av
              hovedpremien. Det trekkes en vinner av hovedpremien som vil bli
              tildelt etter endt kampanjeperiode. (Etter 14. juli). Hovedpremien
              vil bookes og koordineres av en tredjepari og kan ikke byttes med
              kontanter.

              <br />
              <br />

              Det trekkes også 91 delpremier i kampanjeperioden. De trekkes ved
              «instant win» på kampanjenettsiden. Det trekkes en vinner
              tilfeldig hver dag. Det er en delpremie per vinner. Når deltakeren
              registrerer seg i konkurransen får de umiddelbart vite om de har
              vunnet en delpremie. Da kan de velge mellom en Freia-drikkeflaske,
              Freia-badehåndkle og Freia-paraply. Delpremiene blir sendt i
              posten fortløpende.

              <br />
              <br />

              Har du registrert deg i konkurransen er du med i trekningen av
              hovedpremien selv om du har vunnet en delpremie. Premien må
              aksepteres slik som den er, og kan ikke byttes inn mot kontanter.
              Mondelez er ikke ansvarlige for eventuelle skader som måtte oppstå
              under transport/utsendelse av delpremien.

              <br />
              <br />

              Vinner må selv ta ansvar for eventuell gevinstskatt.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>KAMPANJEPERIODE</span>
              <br />
              15.04.2024 - 14.07.2024
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>HVORDAN DELTA</span>
              <br />
              1. Gå til Freia.no og finn konkurransen ved å trykke på «Vinn
              Norgesferie» banner.
              <br />
              2. Deretter trykk på «delta i konkurranse»
              <br />
              3. Fyll ut det elektroniske skjemaet med dine opplysninger for å
              delta i premietrekningen. Det er ikke et krav å kjøpe produkter
              for å delta.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>SLIK BLI VINNEREN PLUKKET UT</span>
              <br />
              Hovedpremien:
              <br />
              Norgesferie til kr. 100 000,- <br />
              Det trekkes en vinner blant alle som har registrert seg etter endt
              kampanjeperiode (14. juli). <br />
              <br />
              Delpremier: <br />
              Det trekkes en tilfeldig vinner hver eneste dag. Det trekkes
              totalt 91 vinnere, som vil motta en valgt delpremie i posten.
              Beslutningen som blir tatt er endelig, og kan ikke overklages.
              Rimelige anstrengelser vil bli gjort for å kontakte vinnerne.
              Dersom en ubekreftet vinner ikke registrerer på første kontakt
              innen 7 dager, forbeholder vi oss retten til å diskvalifisere
              denne deltakeren og tildele premien til en reserve valgt ut på
              samme måte. <br />
              <br />
              Det er deltakernes ansvar å oppgi riktig e-postadresse og
              kontaktinformasjon på nettsiden. Fyller en ikke ut nødvendig
              informasjon innen 30 minutter vil de få en påminnelse på mail om å
              fylle ut nødvendig informasjon. Når all nødvendig informasjon er
              fylt ut vil de kunne motta premien sin. <br />
              <br />
              Arrangøren er ikke ansvarlig for en deltaker som legger inn et
              vinnerkrav for sent, dersom denne e-posten av en eller annen grunn
              kommer på avveie. <br />
              <br />
              Man kan delta flere ganger ved gyldig EAN kode. Man øker
              vinnersjansen ved å delta flere ganger, men man kan kun vinner en
              delpremie en gang.
              <br />

              <br />
            </p>

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>DISKVALIFISERING</span>
              <br />
              Mondelez og andre partnere forbeholder seg retten til å
              diskvalifisere deltakere som bryter med konkurransereglene, deltar
              med upassende kommentarer eller forsøker å sabotere konkurransen.
              Disse deltagerne vil også bli utestengt. Upassende eller støtende
              kommentarer fjernes.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>FORBEHOLD</span>
              <br />
              Mondelez og andre partnere i denne konkurransen tar ikke ansvar
              for eventuelle trykkfeil eller andre utilsiktete misforståelser.
              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Hvis, av en eller annen grunn, kampanjen ikke kan bli gjennomført
              som planlagt, vil Mondelez reservere seg retten til å, når som
              helst og uten forvarsel, avbryte, avslutte, endre eller utsette
              hele eller deler av kampanjen. Arrangørene vil ikke være
              ansvarlige for kampanjen eller premien, og tar ikke særlig ansvar
              for deltakelse som er ugyldig på grunn av tekniske problemer
              utenfor arrangørens kontroll eller problemer angående tilgang til
              nettsiden. Alle internettkostnader og andre kostnader som kan
              oppstå forbundet med deltakelse i kampanjen, er utelukkende
              deltakerens ansvar.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>PERSONOPPLYSNINGER</span>
              <br />
              Ved deltagelse i konkurransen kommer deltakeren til å måtte gi fra
              seg visse personopplysninger, som navn og kontaktinformasjon.
              Mondelez vil behandle personopplysninger for å oppfylle sine
              forpliktelser vedrørende konkurransen, blant annet for å kontakte
              deltakerne og dele ut premiene. Vinnerne vil kunne annonseres på
              Facebook-sidene til Freia og freia.no, presentert med fornavn og
              eventuelt by. Mottakelse av premie forutsetter at vinneren
              samtykker til dette. Etter at vi har delt ut premiene, vil listen
              over kontaktopplysninger bli slettet. Listen slettes senest 12
              måneder etter at kampanjen er avsluttet.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Dine personopplysninger vil bli delt med nøye utvalgte
              tredjeparter, Consultix som håndterer trekkingen av vinnere, og
              Pulse Communications som håndterer det tekniske rundt
              konkurransen. Mondelez er i slike tilfeller
              personopplysningsansvarlig og tredje part er prosessfullmektige.
              Ved å delta i konkurransen og akseptere disse vilkårene, samtykker
              du til at Mondelez behandler og gir videre dine personopplysninger
              til ovenfor beskrevne gjøremål.
              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              Du har rett til å tilbakekalle ditt samtykke til behandlingen av
              personopplysninger. Tilbakekalles dette samtykket innen vinnerne
              en kåret, anses du ikke lengre å oppfylle kravene for å delta i
              konkurransen, og vi kommer ikke til å benytte dine
              personopplysninger videre. Du har rett til å gratis, en gang per
              kalenderår, etter skriftlig underskrevet søknad stilet til
              Mondelez, å få beskjed om hvilke personopplysninger Mondelez har
              om deg og hvordan disse behandles. Etter loven har du også rett
              til å be om korrigering av eventuelle uriktige personopplysninger
              Mondelez måtte ha.
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              For mer informasjon om behandling av personopplysninger,se her:
              <br />
              <a
                href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d"
                target="_blank"
              >
                https://eu.mondelezinternational.com/privacy-notice?sc_lang=nb-no&siteId=6oyH%2ft29EQgVQQ6KPOLGAQ%3d%3d
              </a>

              <br />
            </p>

            <br />

            <p class="terms-container__text__sub-paragraph__subtitle">
              <span>KONTAKT</span>
              <br />
              Har du spørsmål om vilkårene eller konkurransen generelt,kan du ta
              kontakt via Mondelez forbrukerkontakt, tlf. 21 53 08 57.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="terms-footer">
      <img
        class="sticker sticker__map2 animation3"
        src="/assets/stickers/Map.png"
      />

      <h1>
        SJEKK UT KONKURRANSEN
      </h1>
      <router-link to="/">
        <ButtonComponent
          class="terms-footer__button"
          theme="primary"
          label="KONKURRANSE"
        ></ButtonComponent>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";

export default {
  components: {
    ButtonComponent,
  },
};
</script>

<style lang="scss">
.terms-container-parent {
  @media (max-width: $mobile) {
    position: relative;
    overflow-x: clip;
  }
}

.terms-container {
  position: relative;

  width: 80%;
  max-width: 1100px;
  background-color: white;
  border-radius: 25px;
  margin: auto;
  margin-top: 125px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

  text-align: center;

  padding: 75px;

  @media (max-width: $tablet) {
    width: 95%;
    padding: 50px 20px 50px 20px;
  }

  @media (max-width: $mobile) {
    padding: 50px 15px 50px 15px;
    width: 100%;
    margin-top: 85px;
  }

  &__image {
    position: absolute;
    width: 265px;
    left: -12.69%;
    top: 50px;

    @media (max-width: 1375px) {
      display: none;
    }
  }

  &__text {
    max-width: 820px;
    margin: auto;

    &__main-paragraph {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 28.8px;
      margin-bottom: 1.5rem;
    }

    &__sub-paragraph {
      text-align: left;
      line-height: 25px;

      &__title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
      }

      &__subtitle {
        font-weight: 400;

        &__main {
          @media (max-width: $mobile) {
            max-width: 40%;
            word-break: normal;
          }
        }
      }
    }
  }

  h1 {
    color: var(--primary);
  }

  h2 {
    color: var(--primary);
    margin-top: 16px;
  }

  p {
    color: var(--primary);
    line-height: 1.6rem;
  }

  &__button-parent {
    margin-top: 75px;
    overflow: hidden;

    &__paragraph {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .button {
    padding: 1rem;
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    line-break: anywhere;
    color: var(--primary);
  }

  span {
    font-weight: 900;
    font-family: var(--paragraph-font);
    line-height: 26px;

    @media (max-width: $mobile) {
      font-size: 1.375rem;
    }
  }
}

.terms-footer {
  position: relative;
  width: 80%;
  max-width: 1100px;
  border-radius: 25px;
  margin: auto;

  text-align: center;
  padding: 75px 0px;

  &__button {
    width: 80%;
    max-width: 300px;
    margin-top: 25px;
    background-color: var(--primary);

    @media (max-width: $mobile) {
      margin-left: 50%;
      transform: translate(-50%);
      padding: 0.25rem;
    }

    &:hover {
      background-color: var(--primary);
      color: white;
    }

    span {
      font-size: 1.5rem;
    }
  }

  a {
    color: var(--secondary);
  }
}
</style>
