<template><TermsSection /></template>

<script>
import TermsSection from "@/components/TermsSection.vue";

export default {
  components: {
    TermsSection,
  },
};
</script>

<style lang="scss"></style>
