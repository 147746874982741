<template>
  <div class="container">
    <HeroSection />

    <HowItWorks />
    <Form />
    <Carousel v-if="houses" :houses="houses"></Carousel>
  </div>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import Form from "@/components/Form.vue";
import HowItWorks from "@/components/HowItWorks.vue";
import Carousel from "@/components/Carousel.vue";
import houses from "/prices.json";

export default {
  components: {
    HeroSection,
    Form,
    HowItWorks,
    Carousel,
  },
  computed: {
    houses() {
      return houses;
    },
  },
};
</script>

<style lang="scss"></style>
