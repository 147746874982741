<template>
  <div class="participate-two-parent-two">
    <section class="participate-two" id="delta">
      <div class="container">
        <div v-if="!submitted">
          <h1>FYLL INN ADRESSEN DIN UNDER FOR Å MOTTA PREMIEN.</h1>

          <h2 class="participate-two__subtitle">
            Du kan velge mellom Freia badehåndkle, drikkeflaske eller paraply.
            Vi sender delpremien til oppgitt adresse.
          </h2>

          <form @submit.prevent="submit" class="participate-two__form">
            <div class="participate-two__form-inputs">
              <div class="participate-two__dropdown">
                <InputComponent
                  prefix="Velg premie*"
                  class="participate-two__dropdown"
                >
                  <select required v-model="data.prize">
                    <option value="umbrella">FREIAS PARAPLY</option>
                    <option value="water bottle">FREIAS VANNFLASKE</option>
                    <option value="beach towel">FREIAS BADEHÅNDKLE</option>
                  </select>
                </InputComponent>
              </div>

              <InputComponent
                :placeholder="labels.address"
                required
                v-model="data.address"
                :is-valid="validator.fields.address.valid"
                name=""
                class="participate-two__address"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.city"
                required
                v-model="data.city"
                :is-valid="validator.fields.city.valid"
                name="city"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.zipcode"
                required
                v-model="data.zipcode"
                :is-valid="validator.fields.zipcode.valid"
                name="zipcode"
              >
              </InputComponent>
            </div>

            <p>*Påkrevd felt</p>

            <div class="participate-two__buttons">
              <ButtonComponent
                label="SEND INN"
                theme="tertiary"
                success-label="Fullført"
                type="submit"
                :promise="promise"
                error-label="NOE GIKK GALT.."
                loading-label="Laster..."
                :disabled="!validator.passes"
                class="participate-two__button"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Validator from "@/mixins/validator";
import { CheckboxComponent } from "vue-elder-checkbox";
import { InputComponent } from "vue-elder-input";
import { ScrollToAnchor } from "@/utils";
import axios from "axios";

import { Clone } from "@/utils";

const template = {
  email: null,
  prize: null,
  address: null,
  city: null,
  zipcode: null,
};

export default {
  mounted() {
    const email = this.$route.query.email;

    this.data.email = email;
  },
  mixins: [Validator],
  data() {
    return {
      data: Clone(template),
      promise: null,
      disableCodePopover: false,
      submitted: false,
      validator: {
        rules: {
          email: "required|email",
          prize: "required",
          address: "required",
          city: "required",
          zipcode: "required",
        },
        labels: "labels",
        data: "data",
      },
    };
  },
  computed: {
    labels() {
      return {
        address: "Adresse*",
        city: "By*",
        zipcode: "Postkode*",
      };
    },
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async submit() {
      if (!this.validator.passes) return;
      this.promise = fetch("/.netlify/functions/claim", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.data),
      }).then(() => {
        this.$router.push("/thank-you2");
      });
    },
    reset() {
      this.data = Clone(template);
    },
  },
  components: {
    CheckboxComponent,
    InputComponent,
  },
};
</script>

<style lang="scss">
.participate-two-parent-two {
  width: 100vw;
  background-color: var(--primary);
}

.participate-two {
  padding-block: 7rem;
  text-align: center;
  color: var(--primary-contrast);
  max-width: 800px;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media (max-width: $mobile) {
    width: 90%;
    padding-bottom: 100px;
  }

  &__subtitle {
    margin: auto;
    margin-top: 2rem;
    max-width: 650px;

    @media (max-width: $mobile) {
      margin-top: 1.5rem;
    }
  }

  span {
    font-family: var(--paragraph-font);

    @media (max-width: $mobile) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  select {
    border-radius: var(--vue-elder-border-radius) 0 0
      var(--vue-elder-border-radius);
    // padding: 0.9em 1.1em;
    font-weight: bold;
    color: #3b1905;
    font-family: "FS ME Bold";
    appearance: none;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;

    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'  stroke='%233b1905' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.25rem;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .elder-button {
      min-width: 20rem;
      margin-bottom: 2rem;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 3rem;
    color: white;
    font-family: var(--secondary-font);
    display: flex;
    padding: 10px 30px;
  }

  &__form {
    position: relative;

    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    &-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem 1rem;

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
  }

  .elder-checkbox {
    margin-bottom: 1.5rem;
    text-align: left;

    &--checked {
      .elder-checkbox__box {
        border-color: white;
        background: var(--primary);
      }
    }
    align-self: center;

    &__label {
      a {
        text-decoration: underline;
        color: white;
      }
    }

    &__box {
      margin-right: 1rem;
      border-color: white;
      background: white;
      border-color: white;
      border-width: 1px;
      border-radius: 50px;
    }
    &__required {
      font-weight: bold;
      margin-left: 2px;
      color: var(--primary);
    }
  }

  .elder-input {
    &__element {
      border-radius: var(--vue-elder-border-radius) 0 0
        var(--vue-elder-border-radius);
      padding: 22.5px;
      font-weight: bold;
      color: black;
    }

    &__prefix {
      font-weight: bold;
      position: relative;
      border-right: none;
      border-radius: var(--vue-elder-border-radius);
      margin: auto;
      padding: 22px;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        height: 40%;
        width: 2px;
      }
    }
    &__field {
      &--focus {
        border-color: white;
      }
      &--invalid {
        border-color: var(--primary) !important;
      }
    }
  }

  .v-popover {
    // text-align: right;
    margin: 0.3rem 0;
    // margin-right: 1.5rem;
    .trigger {
      display: flex !important;
      justify-content: space-between;
      padding: 10px 30px;

      @media (max-width: $mobile) {
        flex-direction: column;
        gap: 15px;
      }

      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
        cursor: pointer;
      }
    }
  }

  &__terms-font {
    font-size: 0.9rem;
    display: inline;
  }

  h1 {
    color: var(--primary-contrast);
  }

  h2 {
    color: var(--primary-contrast);
  }

  .ean-code {
    @media (min-width: $mobile) {
      grid-column: span 2;
    }
  }

  &__dropdown {
    select {
      font-family: var(--secondary-font);
    }

    @media (min-width: $mobile) {
      grid-column: span 2;
    }
  }

  &__address {
    @media (min-width: $mobile) {
      grid-column: span 2;
    }
  }

  &__button {
    color: var(--primary);

    @media (max-width: $mobile) {
      padding: 0.9rem;

      span {
        font-size: 1.25rem;
      }
    }
  }
}

.popover {
  position: relative;
  z-index: 3;
  background-color: var(--secondary);
  color: var(--primary);

  text-align: center;

  padding: 1rem;
  border-radius: 50px;
  max-width: 700px;

  h1 {
    font-size: 3rem;

    @media (max-width: $mobile) {
      font-size: 2rem;
    }
  }

  p {
    margin-top: 10px;
    padding-inline: 3rem;
    font-weight: 700;
    color: var(--primary);
  }

  @media (max-width: $mobile) {
    font-size: 0.8em;
    padding: 0.4rem;
  }

  box-shadow: var(--box-shadow);

  img {
    width: 70%;
    object-fit: contain;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;

    width: 50px;
    height: 50px;
    path {
      fill: var(--primary);
    }
  }
}
</style>
