<template>
  <div>
    <Congratulations />
    <FormTwo />
  </div>
</template>

<script>
import Congratulations from "@/components/Congratulations.vue";
import FormTwo from "@/components/FormTwo.vue";

export default {
  components: {
    Congratulations,
    FormTwo,
  },
};
</script>

<style lang="scss"></style>
