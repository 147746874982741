<template>
  <div class="hero-section-parent">
    <img
      class="sticker sticker__sunglasses animation2"
      src="/assets/stickers/Sunglasses.png"
    />

    <img
      class="sticker sticker__backpack animation3"
      src="/assets/stickers/Backpack.png"
    />

    <img
      class="sticker sticker__tickets animation1"
      src="/assets/stickers/Tickets.png"
    />

    <img
      class="sticker sticker__kayak animation3"
      src="/assets/stickers/Kayak.png"
    />

    <section class="hero-section"></section>

    <img
      class="sticker sticker__plane animation1"
      src="/assets/stickers/Plane.png"
    />

    <img
      class="sticker sticker__freia animation2"
      src="/assets/stickers/freia.png"
    />

    <img
      class="sticker sticker__tent animation3"
      src="/assets/stickers/Tent.png"
    />

    <ButtonComponent
      class="hero-section__button"
      theme="primary"
      label="DELTA HER"
      @click="scrollTo"
    ></ButtonComponent>
  </div>
</template>

<script>
import { ButtonComponent } from "vue-elder-button";
import { redirect } from "@/utils";

export default {
  components: {
    ButtonComponent,
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 2100,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.hero-section-parent {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  overflow-x: clip;

  width: 100vw;

  h1 {
    margin-top: 20rem;
  }
}

.hero-section {
  background-image: url("@/assets/elements/hero_desktop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 750px;
  height: 500px;
  margin: auto;

  margin-top: 75px;

  @media (max-width: $mobile) {
    background-image: url("@/assets/elements/hero_mobile.png");
    width: 100%;
    max-width: 500px;
  }
}

.sticker {
  &__sunglasses {
    top: 50px;
    left: 10%;

    width: 230px;
    height: 230px;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      left: 15%;
    }
  }

  &__backpack {
    top: 200px;
    left: 0px;

    width: 230px;
    height: 230px;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      left: 5%;
    }
  }

  &__tickets {
    top: 350px;
    left: 7.5%;

    width: 200px;
    height: 200px;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      left: 12.5%;
    }
  }

  &__kayak {
    top: 425px;
    left: -75px;

    width: 375px;
    height: 375px;

    z-index: 1;

    @media (max-width: $tablet) {
      top: 550px;
      left: -100px;
    }

    @media (min-width: $desktop) {
      left: 5%;
    }
  }

  &__plane {
    top: 50px;
    right: 2.5%;

    width: 300px;
    height: 300px;

    z-index: 1;

    @media (max-width: $tablet) {
      right: -75px;
      width: 200px;
      height: 200px;
    }

    @media (min-width: $desktop) {
      right: 15%;
    }
  }

  &__freia {
    top: 250px;
    right: 2.5%;

    width: 225px;
    height: 225px;

    z-index: 1;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      right: 10%;
    }
  }

  &__tent {
    top: 400px;
    right: 4%;

    width: 310px;
    height: 310px;

    z-index: 1;

    @media (max-width: $tablet) {
      top: 650px;
      right: -40px;

      width: 170px;
      height: 170px;
    }

    @media (min-width: $desktop) {
      right: 5%;
    }
  }
}
</style>
