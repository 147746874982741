<template>
  <div class="participate-parent">
    <section class="participate" id="delta">
      <div class="container">
        <div v-if="!submitted">
          <h1>
            REGISTRERING
          </h1>

          <h2 class="participate__subtitle">
            Bli med i konkurransen.
          </h2>

          <form @submit.prevent="submit" class="participate__form">
            <div class="participate__form-inputs">
              <InputComponent
                :placeholder="labels.firstName"
                required
                v-model="data.firstName"
                :is-valid="validator.fields.firstName.valid"
                name="firstName"
              >
              </InputComponent>
              <InputComponent
                :placeholder="labels.lastName"
                required
                v-model="data.lastName"
                name="lastName"
                :is-valid="validator.fields.lastName.valid"
              >
              </InputComponent>
              <InputComponent
                :prefix="data.phone ? '+47' : ''"
                :placeholder="labels.phone"
                required
                v-model="data.phone"
                name="phone"
                :is-valid="validator.fields.phone.valid"
                :validation-message="validator.getFieldError('phone')"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.age"
                required
                v-model="data.age"
                name="age"
                :is-valid="validator.fields.age.valid"
                :validation-message="validator.getFieldError('age')"
              >
              </InputComponent>

              <InputComponent
                :placeholder="labels.email"
                required
                v-model="data.email"
                name="email"
                :is-valid="validator.fields.email.valid"
                :validation-message="validator.getFieldError('email')"
                class="form-email"
              >
              </InputComponent>

              <InputComponent
                class="ean-code"
                prefix="EAN-kode*"
                v-model="data.code"
                :validation-message="validator.getFieldError('code')"
                required
                :is-valid="validator.fields.code.valid && isEanCodeValid()"
              >
                <template v-slot:validation-message>
                  <Message
                    value="EAN-koden er dessverre feil, <br/> vennligst prøv igjen."
                  />
                </template>
              </InputComponent>
            </div>

            <v-popover
              trigger="click"
              class="popover-component"
              :close="true"
              delay="0"
              offset="0"
              placement="top"
              :disabled="disableCodePopover"
              @hide="disableCodePopover = false"
            >
              <slot>
                <div>*Påkrevd felt</div>
              </slot>
              <slot name="trigger">
                <div class="participate__ean-text">
                  Hvor finner jeg EAN-koden?
                </div>
              </slot>

              <template #popover>
                <h1>EAN-Kode</h1>

                <p>
                  Det kan du finne på baksiden av pakningen.
                </p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  @click="disableCodePopover = true"
                >
                  <path
                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                  />
                </svg>
                <img alt="strekkode" class="barcode" src="/assets/ean.png" />
              </template>
            </v-popover>

            <CheckboxComponent required v-model="termsAccepted">
              <h2 class="participate__terms-font">
                <span>
                  Kryss av her om du godtar
                  <router-link to="/Terms"> konkurransebetingelsene</router-link
                  >.*
                </span>
              </h2>
            </CheckboxComponent>

            <CheckboxComponent required v-model="privacyAccepted">
              <h2 class="participate__terms-font">
                <span>
                  Takk for at du deler dine data med oss. Vi behandler alltid
                  personlig data på en respektfull og rettferdig måte, klart
                  begrenset av formålet til denne kampanjen. Vil du vite mer om
                  hvordan vi behandler data, kan du lese mer i vår
                  <a
                    href="https://privacy.mondelezinternational.com/eu/no-NO/privacy-notice/"
                    >personvernerklæring*</a
                  >.
                </span>
              </h2>
            </CheckboxComponent>

            <CheckboxComponent v-model="data.newsletter" name="newsletter">
              <h2 class="participate__terms-font">
                <span>
                  Jeg vil gjerne motta nyheter og informasjon fra Freia.
                </span>
              </h2>
            </CheckboxComponent>

            <div class="participate__buttons">
              <ButtonComponent
                label="SEND INN"
                theme="primary"
                success-label="Fullført"
                type="submit"
                :promise="promise"
                error-label="NOE GIKK GALT.."
                loading-label="Laster..."
                :disabled="
                  !validator.passes ||
                    !privacyAccepted ||
                    !termsAccepted ||
                    !isEanCodeValid()
                "
                class="participate__button"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Validator from "@/mixins/validator";
import { CheckboxComponent } from "vue-elder-checkbox";
import { InputComponent } from "vue-elder-input";
import { ScrollToAnchor } from "@/utils";
import axios from "axios";

import Message from "@/components/Message.vue";

import { Clone } from "@/utils";

const template = {
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  newsletter: false,
  code: null,
  age: null,
};

export default {
  mixins: [Validator],
  data() {
    return {
      data: Clone(template),
      termsAccepted: null,
      privacyAccepted: null,
      promise: null,
      disableCodePopover: false,
      submitted: false,
      validator: {
        rules: {
          firstName: "required",
          lastName: "required",
          phone: "required|phone",
          email: "required|email",
          code: "required",
          age: "required|numeric|min:18",
        },
        labels: "labels",
        data: "data",
      },
    };
  },
  computed: {
    labels() {
      return {
        firstName: "Fornavn*",
        lastName: "Etternavn*",
        email: "E-post*",
        phone: "Telefon*",
        code: "EAN-KODE*",
        age: "Din alder*",
      };
    },
  },
  methods: {
    isEanCodeValid() {
      const validEANs = [
        "7622202039331",
        "7622201760519",
        "7622300476236",
        "7622202016455",
        "7622202016950",
        "7622202016929",
        "7622201729783",
        "7622210303646",
        "7622201746148",
        "7622201393410",
        "7622201389253",
        "7310510002498",
        "7622210678058",
        "7622201729783",
        "7622201721787",
        "7040110569908",
        "7040110571307",
        "7622300414399",
        "7622300249021",
        "7040110569908",
        "7040110571307",
        "7622300414399",
        "7622300249021",
        "7622210886644",
        "7622210882936",
        "7622210886477",
        "7622201521349",
        "7622210831064",
        "7622201517120",
        "7040110569908",
        "7040110571307",
        "7040110572304",
        "7040110573004",
        "7622300414399",
        "7622300249021",
        "7622300222161",
        "7622201119676",
        "7622200506743",
        "7622210678546",
        "7622201428976",
        "7622210712172",
        "7622210667007",
        "7622201678487",
        "7040110069200",
        "7622400751011",
        "7040110602605",
        "7310511250706",
        "7622300086183",
        "7622400750854",
        "7622210648105",
        "7622210648112",
        "7040110650507",
        "7040110650705",
        "7040110650408",
        "7622210077387",
        "7310511257507",
        "7040110603107",
        "7040110648207",
        "7040110648504",
        "7040110655403",
        "7622400974434",
        "7622210410337",
        "7622210680808",
        "7622210816672",
        "7622201771874",
        "7622400928727",
        "7622400633768",
        "7622201517953",
        "7622201497637",
        "7622300635497",
        "7622400633744",
        "7622300399641",
        "7622210559715",
        "7622210738127",
        "7622210738134",
        "7622210882936",
        "7622210886644",
        "7622210886491",
        "7622210886477",
        "7622300774745",
        "7622300774783",
        "7622300137847",
        "7622300581244",
        "7622300348342",
        "7622300580988",
        "7622210408020",
        "7622201124564",
        "7622300512347",
        "7622210510228",
        "7622400646065",
        "7622400984235",
        "7622400646089",
        "7622400646225",
        "7622210776907",
        "7040110257904",
        "7040110258000",
        "7040110257805",
        "7040110258109",
        "7622300047276",
        "7622300050009",
        "7622300418151",
        "7622300123239",
        "7622400944635",
        "7622400944581",
        "7622400944659",
        "7622400944352",
        "7622400944543",
        "7622210855251",
        "7622400948237",
        "7622400948251",
        "7622210159861",
        "7622300435530",
        "7622300473983",
        "7040110310807",
        "7622210115720",
        "7622210115782",
        "7622300327958",
        "7622300750114",
        "7622300591052",
        "7622201636890",
        "7622201637255",
        "7622201636920",
        "7622300277239",
        "7622300286675",
        "7622300286811",
        "7622202017896",
        "7622201507176",
        "7622400750823",
        "7622300123215",
        "7622201697020",
      ];

      if (validEANs.includes(this.data.code)) {
        return true;
      } else {
        return false;
      }
    },
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async executeRecaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute("6Ldpua8pAAAAAEGYQi-EqjRN1yvshWKsQ6zpjXL7", {
              action: "submit",
            })
            .then((token) => {
              resolve(token);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    },
    async submit() {
      if (
        !this.validator.passes ||
        !this.privacyAccepted ||
        !this.isEanCodeValid()
      )
        return;

      try {
        const token = await this.executeRecaptcha();

        this.promise = fetch("/.netlify/functions/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...this.data, recaptcha_token: token }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data === true) {
              this.$router.push(`/you-won?email=${this.data.email}`);
            } else if (data === false) {
              this.$router.push("/thank-you");
            }
          });

        // this.promise = new Promise((resolve, reject) => {
        //   setTimeout(() => {
        //     const jsonResponse = true;
        //     const response = new Response(JSON.stringify(jsonResponse), {
        //       status: 200,
        //       headers: { "Content-Type": "application/json" },
        //     });

        //     resolve(response);
        //   }, 5000);
        // })
        //   .then((response) => response.json())
        //   .then((data) => {
        //     if (data === true) {
        //       this.$router.push(`/you-won?email=${this.data.email}`);
        //     } else {
        //       this.$router.push("/thank-you");
        //     }
        //   });
      } catch (error) {
        console.error("Error submitting form with reCAPTCHA:", error);
        this.$router.push("/error");
      }
    },
    reset() {
      this.data = Clone(template);
      this.termsAccepted = null;
      this.privacyAccepted = null;
    },
  },
  components: {
    CheckboxComponent,
    InputComponent,
    Message,
  },
};
</script>

<style lang="scss">
.participate-parent {
  width: 100vw;
  background-color: var(--primary);
}

.participate {
  padding-block: 7rem;
  text-align: center;
  color: var(--primary-contrast);
  max-width: 800px;
  margin: auto;
  margin-top: 3rem;

  @media (max-width: $mobile) {
    width: 90%;
    padding-bottom: 100px;
  }

  &__subtitle {
    @media (max-width: $mobile) {
      margin-top: 0.5rem;
    }
  }

  span {
    font-family: var(--paragraph-font);

    @media (max-width: $mobile) {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  select {
    border-radius: var(--vue-elder-border-radius) 0 0
      var(--vue-elder-border-radius);
    padding: 0.9em 1.1em;
    font-weight: bold;
    color: #3b1905;
    font-family: "FS ME Bold";
    appearance: none;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;

    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'  stroke='%233b1905' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.25rem;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .elder-button {
      min-width: 20rem;
      margin-bottom: 2rem;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 3rem;
  }

  &__form {
    position: relative;

    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    &-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem 1rem;

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
    }
  }

  .elder-checkbox {
    margin-bottom: 1.5rem;
    text-align: left;

    &--checked {
      .elder-checkbox__box {
        border-color: white;
        background: var(--primary);
      }
    }
    align-self: center;

    &__label {
      a {
        text-decoration: underline;
        color: white;
      }
    }

    &__box {
      margin-right: 1rem;
      border-color: white;
      background: white;
      border-color: white;
      border-width: 1px;
      border-radius: 50px;
    }
    &__required {
      font-weight: bold;
      margin-left: 2px;
      color: var(--primary);
    }
  }

  .elder-input {
    &__element {
      border-radius: var(--vue-elder-border-radius) 0 0
        var(--vue-elder-border-radius);
      padding: 22.5px;
      font-weight: bold;
      color: black;
    }

    &__prefix {
      font-weight: bold;
      position: relative;
      border-right: none;
      border-radius: var(--vue-elder-border-radius);
      margin: auto;
      padding: 22px;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        height: 40%;
        width: 2px;
      }
    }
    &__field {
      &--focus {
        border-color: white;
      }
      &--invalid {
        border-color: var(--primary) !important;
      }
    }

    &__validation-message {
      p {
        color: var(--primary);
      }

      position: absolute;
      left: 50%;
      top: 45%;

      transform: translate(-50%, -50%);

      color: var(--primary);
      background: var(--secondary);
      padding: 5px;
      width: 100%;
      font-weight: 700;
      text-align: center;
      font-size: 20px;
      z-index: 10;
      border-radius: var(--vue-elder-border-radius);
      box-shadow: var(--box-shadow);
      z-index: -1;
      visibility: hidden;

      @keyframes showError {
        0% {
          overflow: hidden;
          visibility: visible;
          z-index: 1;
        }
        99% {
          overflow: hidden;
          visibility: hidden;
          z-index: 1;
        }
        100% {
          overflow: hidden;
          visibility: hidden;
          z-index: -1;
        }
      }

      animation-name: showError;
      animation-duration: 4s;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
        width: 30px;
        height: 30px;
        path {
          fill: white;
        }
      }
    }
  }

  .v-popover {
    // text-align: right;
    margin: 0.3rem 0;
    // margin-right: 1.5rem;
    .trigger {
      display: flex !important;
      justify-content: space-between;
      padding: 10px 30px;

      @media (max-width: $mobile) {
        flex-direction: column;
        gap: 15px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__terms-font {
    font-size: 0.9rem;
    display: inline;
  }

  h1 {
    color: var(--primary-contrast);
  }

  h2 {
    color: var(--primary-contrast);
  }

  .ean-code {
    @media (min-width: $mobile) {
      grid-column: span 2;
    }
  }

  .form-email {
    @media (min-width: $mobile) {
      grid-column: span 2;
    }
  }

  &__button {
    background-color: white;
    color: var(--primary);

    @media (max-width: $mobile) {
      padding: 0.9rem;

      span {
        font-size: 1.25rem;
      }
    }
  }

  &__ean-text {
    text-decoration: underline;
  }
}

.popover {
  position: relative;
  z-index: 3;
  background-color: var(--secondary);
  color: var(--primary);

  text-align: center;

  padding: 1rem;
  border-radius: 50px;
  max-width: 700px;

  h1 {
    font-size: 3rem;

    @media (max-width: $mobile) {
      font-size: 2rem;
    }
  }

  p {
    margin-top: 10px;
    padding-inline: 3rem;
    font-weight: 700;
    color: var(--primary);
  }

  @media (max-width: $mobile) {
    font-size: 0.8em;
    padding: 0.4rem;
  }

  box-shadow: var(--box-shadow);

  img {
    width: 70%;
    object-fit: contain;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;

    width: 50px;
    height: 50px;
    path {
      fill: var(--primary);
    }
  }
}
</style>
